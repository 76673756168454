import { createRouter, createWebHistory} from 'vue-router';

const Home = () => import('../views/Home')
const Artists = () => import('../views/Artists')
const ArtistDetail = () => import('../views/ArtistDetail')
// const MagazineDetail = () => import('../views/MagazineDetail')
const Production = () => import('../views/Production')
const ProductionDetail = () => import('../views/ProductionDetail')
const Programs = () => import('../views/Programs')
const ProgramDetail = () => import('../views/ProgramDetail')
const Info = () => import('../views/Info')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ate',
    name: 'Ate',
    component: Home
  },
  {
    path: '/artists',
    name: 'Artists',
    component: Artists,
    children: [
      {
        path: 'detail',
        name: 'Adetail',
        component: ArtistDetail
      }
    ]
  },
  {
    path: '/production',
    name: 'Production',
    component: Production,
    children: [
      {
        path: 'detail',
        name: 'Prodetail',
        component: ProductionDetail
      }
    ]
  },
  {
    path: '/programs',
    name: 'Programs',
    component: Programs,
    children: [{
      path: 'detail',
      name: 'Pdetail',
      component: ProgramDetail
    }]
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router