import axios from 'axios';

const service = axios.create({
  baseURL: 'https://api.atenothing.co/portal',
  timeout: 30000
})

// 响应拦截器
service.interceptors.response.use(
  (response)=>{
    if (response.status === 200)
      return response.data
  },
  (err)=>{
    console.log(err);
  }
)
// 请求拦截器
service.interceptors.request.use(
  (config)=>{
    return config
  },  
  (err)=>{
    console.log(err);
  }
)

export default service