<template>
  <div class="ate-body customer-cursor" @contextmenu.native="handlePaste($event)">
    <transition name="fade" mode="out-in">
      <div class="ate-header font-ppe" :class="isShowHeader?'ate-header-show':'ate-header-hide'">
        <router-link to="/" class="header-tab">
          <img src="./assets/logo.png" class="header-logo">
        </router-link> 
        <router-link to="/artists"  v-show="$route.name=='Home' || $route.path.slice(1,8)=='artists'"  class="header-tab">Artists</router-link> 
        <router-link to="/production"  v-show="$route.name=='Home' || $route.path.slice(1,11)=='production'"  class="header-tab">Production</router-link> 
        <router-link to="/programs"  v-show="$route.name=='Home' || $route.path.slice(1,9)=='programs'"  class="header-tab">Projects</router-link> 
        <router-link to="/info"  v-show="$route.name=='Home' || $route.name=='Info'"  class="header-tab">Info</router-link>
        <span v-if="$route.name!='Home'" id="pageDetailInfo"></span>
      </div>
    </transition>
    <div class="ate-contain">
      <router-view v-slot="{ Component,route  }">
          <transition name="fade" mode="out-in">
              <component :is="Component" :key="route.path"/>
          </transition>
      </router-view>
    </div>

    <span class="font-ppe"></span>
    <span class="font-ppf"></span>
    <span class="font-simsum"></span>
  </div>
</template>

<script>
import { getConfig } from '@/network/api'

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      pageDetail: '',
      isShowHeader: true,
      cursorUrl: ''
    }
  },
  methods: {
    handlePaste (event) {
      // 禁用鼠标右键
      event.preventDefault()
      return false
    },
    getCursorUrl () {
      let arg = {}
      arg.configKey = 'sys.common.mouse'
      getConfig(arg).then(
        (res) => {
          let configData = res.data
          let cursorObj = JSON.parse(configData[0].configValue)
          cursorObj.forEach(e=>{
            if (e.status) {
              this.cursorUrl = e.url
              let css = `
                a {
                  cursor: url(`+this.cursorUrl+`),auto !important;
                }
                .customer-cursor {
                  cursor: url(`+this.cursorUrl+`),auto !important;
                }
              `
              let addStyle = document.createElement('style');
              addStyle.type = 'text/css';
              addStyle.id = 'dynamic-style';
              addStyle.innerHTML = css;
              document.getElementsByTagName('head').item(0).appendChild(addStyle);
            }
          })
        }
      ).catch((err) => {
          console.log(err)
        }
      )
    }
  },
  mounted () {
    // 设备切换
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    // console.log(flag,'null - 电脑')
    if (flag) {
      window.location.href = "http://m.atenothing.co/"
    }
    // 屏幕自适应
    function placeholderPic () {
      document.documentElement.style.fontSize=document.documentElement.offsetWidth/60+"px"; //同上
    }
    placeholderPic ();
    window.addEventListener('resize', () => {placeholderPic()}, false)
    // 导航条显示/隐藏
    let that = this;
    function wheelHandler (e) {
      if (e.wheelDeltaY < -40) {
        
        if (that.isShowHeader) {
          that.isShowHeader = !that.isShowHeader
          // console.log('向上滚, 顶部消失'+e.wheelDeltaY)
        }
      }else if (e.wheelDeltaY > 0) {
        
        if (!that.isShowHeader) {
          that.isShowHeader = !that.isShowHeader
          // console.log('向下滚, 顶部出现')
        }
      }
    }
    document.addEventListener('mousewheel', ($event) => {wheelHandler($event)}, false)
    // 添加鼠标样式
    if (document.getElementById('dynamic-style') == null) {
      this.getCursorUrl()
      // let css = `
      //   a {
      //     cursor: url(https://shuo-1304549791.cos.ap-chengdu.myqcloud.com/album/cursor.png),auto !important;
      //   }
      //   .customer-cursor {
      //     cursor: url(https://shuo-1304549791.cos.ap-chengdu.myqcloud.com/album/cursor.png),auto !important;
      //   }
      // `
      // let addStyle = document.createElement('style');
      // addStyle.type = 'text/css';
      // addStyle.id = 'dynamic-style';
      // addStyle.innerHTML = css;
      // document.getElementsByTagName('head').item(0).appendChild(addStyle);
    }
  }
}
</script>

<style lang="scss" scoped>
$r: 32;
.ate-header-show {
  animation: show 0.2s linear; 
  animation-fill-mode:forwards;
}
@keyframes show {
    0% {
      transform: translateY(-50/$r+rem);
    }
    100% {
    }
}
.ate-header-hide {
  animation: hide 0.2s linear; 
  animation-fill-mode:forwards;
}
@keyframes hide {
    0% {
    }
    100% {
      transform: translateY(-50/$r+rem);
    }
}
</style>
