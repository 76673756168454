import request from './request'

// 配置信息
export const getConfig = data => {
  return request({
      url: '/common/getConfig',
      method: 'POST',
      data
  })
}

// 二级页面 - Artists, Production, Program
export const getDataLevel = data => {
  return request({
      url: '/common/getDataLevel',
      method: 'POST',
      data
  })
}

// Artist 详情
export const getArtistDetail = data => {
  return request({
      url: '/artist/getWorkList',
      method: 'POST',
      data
  })
}

// Production 详情
export const getProductionDetail = data => {
  return request({
      url: '/production/getWorkList',
      method: 'POST',
      data
  })
}

// Programs 详情
export const getProgramDetail = data => {
  return request({
    url: '/program/getWorkList',
    method: 'POST',
    data
})
}
